let header = document.querySelector("header.main")
let msg = document.querySelector(".msg-kv")
let humBtn = document.querySelector(".btn-inner")
let humInner = document.querySelector(".header-menu")
window.addEventListener('load',function(){
    msg.classList.add("show")
})
window.addEventListener("scroll",function(){
    let current = window.scrollY
    if(current > window.innerHeight){
        header.classList.add("following")
    } else {
        header.classList.remove("following")
    }
})
function openHum(){
    if(humBtn.classList.contains("open")){
        humBtn.classList.remove("open")
    } else {
        humBtn.classList.add("open")

    }
}